<template>
  <v-sheet
    class="d-flex align-center justify-center"
    height="100vh"
    color="transparent"
  >
    <v-sheet
      v-if="isLoading"
      width="150"
      color="transparent"
      class="text-center"
    >
      <div class="mb-2 text-h6 font-weight-bold">登入中</div>
      <v-progress-linear
        color="primary"
        buffer-value="0"
        stream
        reverse
      ></v-progress-linear>
    </v-sheet>
    <template v-else>
      <v-card class="pa-4 text-center" outlined width="350">
        <v-icon
          :color="isLoginFailed ? 'error' : 'success'"
          x-large
          class="ma-2"
          >{{
            isLoginFailed
              ? 'mdi-close-circle-outline'
              : 'mdi-check-circle-outline'
          }}</v-icon
        >
        <v-card-text class="text-h5 font-weight-bold">
          登入{{ isLoginFailed ? '失敗' : '成功' }}
        </v-card-text>
        <div v-if="errorMessage" class="error--text mb-1 text-caption">
          {{ errorMessage }}
        </div>
        <v-card-text v-if="!isLoginFailed"
          >...{{ countdownSec }}s 將跳轉至首頁</v-card-text
        >
        <v-btn
          v-else
          color="ems-aquamarine"
          class="my-2"
          @click="$router.push({ path: '/login' })"
          >確定</v-btn
        >
      </v-card>
    </template>
  </v-sheet>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    code: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      countdownSec: 2,
      isLoginFailed: false
    }
  },
  async mounted() {
    if (this.code) {
      try {
        const params = {
          username: '__code__',
          password: this.code
        }
        await this.login(params)
        this.isLoading = false
        const timer = setInterval(() => {
          if (this.countdownSec) this.countdownSec -= 1
          else {
            clearInterval(timer)
            this.$router.push({ name: 'Home' })
          }
        }, 1000)
      } catch (error) {
        console.error(error.error_description)
        this.errorMessage = error.error_description
        this.isLoading = false
        this.isLoginFailed = true
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'user/login'
    })
  }
}
</script>
